import type { GetStaticPaths, GetStaticProps } from 'next'
import {
  getRouteByPath,
  getSiteConfiguration,
  getEventPageByPath,
  getStoryPageByPath,
} from '@liftfoils/services/sanity-service'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { ROUTE_BASE_EVENTS, ROUTE_BASE_STORIES } from '@liftfoils/configs'
import { getShopifyDataFromPageBuilder } from '@liftfoils/services/shopify-service'
import { DefaultPage } from '@liftfoils/page'

export const getStaticPaths: GetStaticPaths = async () => {
  // const paths: string[] = await getClient(false).fetch(
  //   groq`*[!(_id in path('drafts.**')) && defined(path.current)][].path.current`,
  // )

  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  PageResponse<PageBuilderSection[]>
> = async ({ params: { paths }, locale, preview = false }) => {
  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`

  const isEventPage = paths && `/${paths[0]}` === ROUTE_BASE_EVENTS && paths[1]
  const isStoryPage = paths && `/${paths[0]}` === ROUTE_BASE_STORIES && paths[1]

  const pageData = await (async () => {
    if (isEventPage) {
      return await getEventPageByPath(path, locale, preview)
    }

    if (isStoryPage) {
      return await getStoryPageByPath(path, locale, preview)
    }

    return await getRouteByPath(path, locale, preview)
  })()

  if (!pageData) {
    return {
      notFound: true,
    }
  }

  const siteConfiguration = await getSiteConfiguration(locale, preview)

  const shopifyData = await getShopifyDataFromPageBuilder(
    pageData.pageBuilder,
    locale,
  )

  return {
    props: {
      ...pageData,
      siteConfiguration,
      shopifyData,
      path,
    },
    revalidate: 60,
  }
}

export default DefaultPage
